/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */
import "./layout.scss"

import Mobile from "../components/header/mobile"

import React, { useState } from "react"
import styled, { ThemeProvider } from "styled-components"
import Header from "./header"
import Footer from "./footer"

import { theme, GlobalStyle } from "../global"

const Layout = ({
  children,
  siteTitle,
  siteLogo,
  contact,
  connect,
  headerBreakpoint,
}) => {
  const [open, setOpen] = useState(false)

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <React.Fragment>
        <Header />
        <Mobile open={open} setOpen={setOpen} />
        <Main>{children}</Main>
        <Footer />
      </React.Fragment>
    </ThemeProvider>
  )
}

const Main = styled.div`
  max-height: 100%;
  min-height: 100%;
`

export default Layout
