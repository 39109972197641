import React from "react"
import styled from "styled-components"
import Reaveal from "react-reveal/"
import { Link } from "gatsby"
import Image from "gatsby-image"
import AniLink from "gatsby-plugin-transition-link/AniLink"

export default function InfoList({ data }) {
  return (
    <Wrapper>
      <div className="container">
        {data.items.map((item, index) => {
          return (
            <article key={index} className="info-card-container">
              <div className="title">
                <Reaveal>
                  <div className="text-wrapper">
                    <div dangerouslySetInnerHTML={{ __html: item.copy.html }} />
                    {item.link ? (
                      <AniLink
                        className="info-button"
                        paintDrip
                        duration={1.5}
                        to={item.info_link}
                        hex="#9a2474"
                      >
                        {item.button_text}
                      </AniLink>
                    ) : // <StyledButton to={item.info_link} className="info-button">
                    //   {item.button_text}
                    // </StyledButton>
                    null}
                    <span className="arrow-decoration"></span>
                  </div>
                </Reaveal>
              </div>
              <div className="image">
                <Image
                  style={{
                    maxHeight: "100%",
                    minHeight: "100%",
                  }}
                  fluid={item.info_image.localFile.childImageSharp.fluid}
                />
              </div>
            </article>
          )
        })}
      </div>
    </Wrapper>
  )
}

export const Wrapper = styled.div`
  p {
    font-family: inherit;
    font-size: 1pc;
    font-size: 1rem;
    font-weight: 300;
    margin-bottom: 20px;
    line-height: 1.6;
    color: white;
    margin-bottom: 1.25rem;
    margin-bottom: 2em;
    text-rendering: optimizeLegibility;
  }
  h1 {
    letter-spacing: 0.03em;
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 1.5rem;
    color: #9a2474;
    display: block;
    line-height: 1.2;
    margin-top: 15px;
    text-transform: capitalize;
  }
  .container {
    background-color: #1e2a5b;
    width: 100%;
    /* margin-top: 10px; */
    article {
      display: flex;
      min-height: 600px;
      height: 500px;
      max-height: 500px;
      @media only screen and (max-width: ${props =>
          props.theme.responsive.mobile}) {
        display: block;
        max-height: none;

        min-height: 300px;
        height: 100%;
      }

      &:nth-child(odd) {
        background-color: #9a2474;
        flex-direction: row-reverse;
        h1 {
          color: white;
        }
        p {
          color: white;
          margin-bottom: 2em;
        }
      }

      > div {
        width: 100%;
        /* max-width: 50%;
        min-width: 50%; */
      }

      .title {
        height: 100%;
        width: 100%;
        display: flex;
        .text-wrapper {
          width: 83.333333%;
          max-width: 900px;
          margin: auto;
          padding: 24px 40px;

          .info-button {
            -moz-appearance: none;
            border-radius: 0;
            border-width: 2px;
            cursor: pointer;

            font-weight: 400;
            line-height: normal;
            margin: 0 0 20px;
            margin: 0 0 1.25rem;
            position: relative;
            text-align: center;
            text-decoration: none;
            display: inline-block;
            padding: 26px 52px 27px;
            padding: 1.625rem 3.25rem 1.6875rem;
            font-size: 1pc;
            font-size: 1rem;
            background-color: transparent;
            border: 0.125rem solid white;
            color: white;
            transition: background-color 0.3s ease-out;
            &:hover {
              background-color: inherit;
              color: white;
            }
          }
          @media only screen and (max-width: ${props =>
              props.theme.responsive.mobile}) {
            width: 100%;
          }
        }
      }
    }
  }
`

const StyledButton = styled(Link)`
  -moz-appearance: none;
  border-radius: 0;
  border-width: 2px;
  cursor: pointer;

  font-weight: 400;
  line-height: normal;
  margin: 0 0 20px;
  margin: 0 0 1.25rem;
  position: relative;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  padding: 26px 52px 27px;
  padding: 1.625rem 3.25rem 1.6875rem;
  font-size: 1pc;
  font-size: 1rem;
  background-color: transparent;
  border: 0.125rem solid white;
  color: white;
  transition: background-color 0.3s ease-out;
  &:hover {
    background-color: inherit;
    color: white;
  }
`
