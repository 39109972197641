import React from "react"
import styled from "styled-components"
import Image from "gatsby-image"
import Reveal from "react-reveal/Fade"

export default function Logos({ data }) {
  return (
    <Wrapper>
      <div className="logo-container">
        {data.items.map((item, index) => {
          return (
            <div key={index} className="logo">
              <Image fluid={item.grid_photo.localFile.childImageSharp.fluid} />
            </div>
          )
        })}
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  padding-top: 2rem;

  .logo-container {
    max-width: 900px;
    margin-left: auto;
    margin-right: auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 60px;
    grid-row-gap: 60px;

    @media only screen and (max-width: ${props =>
        props.theme.responsive.mobile}) {
      grid-template-columns: 1fr 1fr;
      padding-left: 2rem;
      padding-right: 2rem;
    }
    .logo {
      height: 100%;
      width: 100%;
    }
  }
`
