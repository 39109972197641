import React from "react"
import { StyledMenu } from "./menu.styled"

import AniLink from "gatsby-plugin-transition-link/AniLink"

function TransitionLink({ link, title }) {
  return (
    <AniLink
      className="tran-link"
      cover
      to={`${link}`}
      direction="left"
      duration={2.5}
      bg="rgb(154, 36, 116)"
    >
      {title}{" "}
    </AniLink>
  )
}

const Menu = ({ open, setOpen }) => {
  return (
    <StyledMenu open={open}>
      <TransitionLink title="Team Leader" link="/team" />
      <TransitionLink title="Services" link="/services" />
      <TransitionLink title="Our Work" link="/projects" />
      <TransitionLink title="Clients" link="/clients" />
      <TransitionLink title="About us" link="/about" />
      <TransitionLink title="contact us" link="/contact" />
    </StyledMenu>
  )
}

export default Menu
