import React, { useEffect } from "react"
import { Link } from "gatsby"
import AniLink from "gatsby-plugin-transition-link/AniLink"

import Instagram from "../svgs/socials/instagram"
import Facebook from "../svgs/socials/facebook"
import LinkDin from "../svgs/socials/in"
import { useScrollData } from "scroll-data-hook"

import ImageLogo from "../images/logo.png"

import styled from "styled-components"

function TransitionLink({ link, title }) {
  return (
    <AniLink
      className="tran-link"
      cover
      to={`/${link}`}
      direction="left"
      duration={2.5}
      bg="rgb(154, 36, 116)"
    >
      {title}{" "}
    </AniLink>
  )
}

function Icon() {
  return (
    <svg
      fill="white"
      height="35px"
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      enableBackground="new 0 0 512 512"
      version="1.1"
      viewBox="0 0 512 512"
      xmlSpace="preserve"
    >
      <path d="M339.392 258.624L512 367.744 512 144.896z"></path>
      <path d="M0 144.896L0 367.744 172.608 258.624z"></path>
      <path d="M480 80H32C16.032 80 3.36 91.904.96 107.232L256 275.264l255.04-168.032C508.64 91.904 495.968 80 480 80zM310.08 277.952l-45.28 29.824a15.983 15.983 0 01-8.8 2.624c-3.072 0-6.112-.864-8.8-2.624l-45.28-29.856L1.024 404.992C3.488 420.192 16.096 432 32 432h448c15.904 0 28.512-11.808 30.976-27.008L310.08 277.952z"></path>
    </svg>
  )
}

export default function Header() {
  return (
    <HeaderWrapper>
      <Column id="top-header">
        <TopNav id="top-container">
          <LogosContainer>
            <LinkWrapper to="/">
              <img id="logo-image" className="header-image" src={ImageLogo} />
            </LinkWrapper>
            <SubscribeContainer></SubscribeContainer>
          </LogosContainer>
          <NavList>
            <TransitionLink title="Team Leader" link="/team" />
            <TransitionLink title="Services" link="/services" />

            <TransitionLink title="Our Work" link="/projects" />

            <TransitionLink title="Clients" link="/clients" />
            <TransitionLink title="About us" link="/about" />
            <TransitionLink title="contact us" link="/contact" />
          </NavList>
        </TopNav>
      </Column>
    </HeaderWrapper>
  )
}

const LinkWrapper = styled(Link)`
  height: 100%;
  cursor: pointer;
  color: none;
`

const LogosContainer = styled.div`
  display: flex;
  align-items: center;
  svg {
    margin-left: 5px;
  }
`

const NavList = styled.div`
.tran-link {
  
  font-size: 16.5px;
   text-transform:capitalize;
   list-style: none;
    border: 0;
    padding: 0;
    font-weight: 600;
    color: #2c4b85;
    margin: 0;
    /* font-weight:600; */
    letter-spacing:0.02em;
    display: block;
    margin-left:15px;
    /* padding: 0 1em; */
    cursor: pointer;
    &:hover {
      color: rgb(154, 36, 116);;
      transition: border-bottom 0.4s ease-out;
    }

}
  display: flex; 
    span {
      font-size: 1rem;
      color: #2c4b85;
      /* font-weight: 600; */
      font-weight: thin;

      letter-spacing: 0.059em;
      /* opacity: 0.75; */
    }
  }
`

const BottomNavigation = styled.div`
  width: 100%;
  height: 3rem;
  max-height: 3rem;
  line-height: 3rem;
  display: flex;
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.1); */
  background-color: #2c4b85;
  /* justify-content: center; */
`

const SubscribeContainer = styled.div`
  height: 100%;
  display: flex;
  margin-left: 1rem;
  span {
    margin-left: 1rem;
    color: #2c4b85;
    align-self: center;
  }
  svg {
    height: 25px;
    width: 25px;
    fill: #9a2474;
  }
`

const HeaderWrapper = styled.div`
  box-shadow: rgba(19, 3, 3, 0.1) 0px 2px 4px 4px;

  @media only screen and (max-width: ${props =>
      props.theme.responsive.mobile}) {
    display: none;
  }
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 8;
  background-color: rgba(0, 0, 0, 0.07);

  transition: background-color 1s ease-in;

  color: #fff;
  .header-image {
    height: 5rem;
    width: 9rem;
  }
`
const Column = styled.div`
  background-color: white;
  display: flex;
  flex-direction: column;
`

const SocialMediaContainer = styled.div`
  display: flex;
  svg {
    fill: #2c4b85;
    height: 25px;
    cursor: pointer;
    width: 25px;
    margin-right: 10px;
  }
`

const Styledlink = styled(Link)`
  font-size: 1rem;
  font-weight: thin;
  letter-spacing: 0.059em;
  list-style: none;
  border: 0;
  padding: 0;
  margin: 0;
  color: inherit;
  height: inherit;
  display: block;
  padding: 0 20px;
  padding: 0 1.25rem;
  cursor: pointer;
  &:hover {
    border-top: 1px thin white;
    border-bottom: 1px thin white;
    /* background-color: #f2c8cf; */
    color: black;

    transition: border-bottom 0.4s ease-out;
  }
`

const TopNav = styled.div`
  width: 100%;

  display: flex;
  height: 120px;

  max-width: 1100px;
  min-width: 1100px;
  margin-right: auto;
  font-style: bold;
  margin-left: auto;

  -webkit-transition: margin-top 1s ease-in, display 1s ease-out;
  transition: margin-top 1s ease-in, display 1s ease-out;
  padding-bottom: 5px;

  align-items: center;
  margin-top: 5px;
  margin-bottom: 10px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.1); */
`

const SocialLinkWrapper = styled.a`
  height: 100%;
  cursor: pointer;
  color: none;
`

// const LinkWrapper = styled.a`
//   height: 100%;
//   cursor: pointer;
//   color: none;
// `
