import React from "react"
import { navigate } from "gatsby"
import { Formik } from "formik"
import * as Yup from "yup"

export const validationSchema = Yup.object({
  name: Yup.string().notRequired("* Required"),
  lastName: Yup.string().notRequired("* Required"),
  email: Yup.string().required("* Email is required"),
  project: Yup.string().notRequired("* Required"),
  textArea: Yup.string().notRequired("* Required"),
})

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

export const initialValues = {
  name: "",
  lastName: "",
  email: "",
  project: "",
  textArea: "",
}

export default function ContactForm({ data, copy }) {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={values => {
        fetch("/", {
          method: "POST",
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          body: encode({ "form-name": "contact", ...values }),
        })
          .then(() => {
            navigate("/thank-you")
          })
          .catch(error => alert(error))
      }}
    >
      {({ handleSubmit, handleChange, values, errors }) => {
        return (
          <div className=" contact-form-wrapper wrap">
            <h1>Contact Us</h1>
            <div className="form-container wrap">
              <form
                name="contact"
                method="post"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                onSubmit={handleSubmit}
              >
                <div className="label-container">
                  <label>
                    <p className="label-title"></p>
                    <input
                      type="text"
                      onChange={handleChange}
                      placeholder="First Name"
                      value={values.name}
                      name="name"
                    />{" "}
                  </label>
                  <label>
                    <p className="label-title"></p>
                    <input
                      type="text"
                      placeholder="Last Name"
                      onChange={handleChange}
                      value={values.lastName}
                      name="lastName"
                    />
                  </label>
                </div>
                <div className="label-container">
                  <label>
                    <p className="label-title"></p>
                    <input
                      type="text"
                      placeholder="Project"
                      onChange={handleChange}
                      value={values.project}
                      name="project"
                    />{" "}
                  </label>
                  <label>
                    <input
                      placeholder="Email"
                      type="text"
                      onChange={handleChange}
                      value={values.email}
                      name="email"
                    />{" "}
                  </label>
                </div>
                <p
                  style={{ marginBottom: "0.5em", color: "red" }}
                  className="label-title"
                >
                  {errors.email}
                </p>

                <div className="label-container">
                  <label>
                    <textarea
                      placeholder="Drop Us A Line"
                      type="text"
                      onChange={handleChange}
                      value={values.textArea}
                      name="textArea"
                    ></textarea>
                  </label>
                </div>
                <button type="submit">Send</button>
              </form>

              <div className="contact-social-media-container">
                <div className="social-media-flex">
                  <p></p>
                  <Email /> cybelle@brownprojectsolutions.com
                </div>
                <div className="social-media-flex">
                  <Phone /> +1 (202) 412-3020
                </div>
                <p style={{ paddingBottom: "1em" }}>
                  123 Linden Boulevard, Suite 6P,
                  <br />
                  Brooklyn, New York, 11226
                </p>

                <div style={{ maxHeight: "300px" }}>
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3026.9619666919607!2d-73.95659978459689!3d40.65276877933832!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c25b6a0fe6fe67%3A0x8a60891ac7ffa0bd!2s123%20Linden%20Blvd%20%236P%2C%20Brooklyn%2C%20NY%2011226%2C%20USA!5e0!3m2!1sen!2sca!4v1585325087181!5m2!1sen!2sca"
                    width="65%"
                    height="100%"
                    aria-hidden="false"
                    tabindex="0"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        )
      }}
    </Formik>
  )
}

function ArrowRight() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      fill="white"
      height="25px"
      width="2em"
      enableBackground="new 0 0 512 512"
      version="1.1"
      viewBox="0 0 512 512"
      xmlSpace="preserve"
    >
      <path
        d="M508.875 248.458l-160-160c-4.167-4.167-10.917-4.167-15.083 0-4.167 4.167-4.167 10.917 0 15.083l141.792 141.792H10.667C4.771 245.333 0 250.104 0 256s4.771 10.667 10.667 10.667h464.917L333.792 408.458c-4.167 4.167-4.167 10.917 0 15.083a10.634 10.634 0 007.542 3.125c2.729 0 5.458-1.042 7.542-3.125l160-160c4.166-4.166 4.166-10.916-.001-15.083z"
        className="active-path"
        data-original="#000000"
      ></path>
    </svg>
  )
}

function Email() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      enableBackground="new 0 0 512 512"
      version="1.1"
      viewBox="0 0 512 512"
      xmlSpace="preserve"
    >
      <path d="M10.688 95.156C80.958 154.667 204.26 259.365 240.5 292.01c4.865 4.406 10.083 6.646 15.5 6.646 5.406 0 10.615-2.219 15.469-6.604 36.271-32.677 159.573-137.385 229.844-196.896 4.375-3.698 5.042-10.198 1.5-14.719C494.625 69.99 482.417 64 469.333 64H42.667c-13.083 0-25.292 5.99-33.479 16.438-3.542 4.52-2.875 11.02 1.5 14.718zM505.813 127.406a10.618 10.618 0 00-11.375 1.542c-46.021 39.01-106.656 90.552-152.385 129.885a10.654 10.654 0 00-3.708 8.271 10.644 10.644 0 004 8.135c42.49 34.031 106.521 80.844 152.76 114.115a10.626 10.626 0 006.229 2.01c1.667 0 3.333-.385 4.865-1.177a10.66 10.66 0 005.802-9.49V137.083a10.663 10.663 0 00-6.188-9.677zM16.896 389.354c46.25-33.271 110.292-80.083 152.771-114.115a10.646 10.646 0 004-8.135 10.654 10.654 0 00-3.708-8.271C124.229 219.5 63.583 167.958 17.563 128.948a10.674 10.674 0 00-11.375-1.542A10.66 10.66 0 000 137.083v243.615c0 4 2.24 7.667 5.802 9.49a10.566 10.566 0 004.865 1.177 10.62 10.62 0 006.229-2.011z"></path>
      <path d="M498.927 418.375c-44.656-31.948-126.917-91.51-176.021-131.365-4-3.26-9.792-3.156-13.729.24-9.635 8.406-17.698 15.49-23.417 20.635-17.563 15.854-41.938 15.854-59.542-.021-5.698-5.135-13.76-12.24-23.396-20.615-3.906-3.417-9.708-3.521-13.719-.24-48.938 39.719-131.292 99.354-176.021 131.365a10.703 10.703 0 00-4.406 7.604 10.67 10.67 0 002.802 8.333C19.552 443.01 30.927 448 42.667 448h426.667c11.74 0 23.104-4.99 31.198-13.688a10.676 10.676 0 002.802-8.323 10.73 10.73 0 00-4.407-7.614z"></path>
    </svg>
  )
}

function Phone() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      enableBackground="new 0 0 384 384"
      version="1.1"
      viewBox="0 0 384 384"
      xmlSpace="preserve"
    >
      <path d="M353.188 252.052c-23.51 0-46.594-3.677-68.469-10.906-10.719-3.656-23.896-.302-30.438 6.417l-43.177 32.594c-50.073-26.729-80.917-57.563-107.281-107.26l31.635-42.052c8.219-8.208 11.167-20.198 7.635-31.448-7.26-21.99-10.948-45.063-10.948-68.583C132.146 13.823 118.323 0 101.333 0h-70.52C13.823 0 0 13.823 0 30.813 0 225.563 158.438 384 353.188 384c16.99 0 30.813-13.823 30.813-30.813v-70.323c-.001-16.989-13.824-30.812-30.813-30.812z"></path>
    </svg>
  )
}
