import React from "react"
import styled from "styled-components"
import BackgroundImage from "gatsby-background-image"

export default function Featured({
  backgroundImage,
  quote,
  isSection,
  sectionTitle,
}) {
  return (
    <CallToAction
      isSection={isSection}
      Tag="section"
      fluid={backgroundImage}
      backgroundColor={`#040e18`}
    >
      {isSection ? (
        <div className="section-carousel">
          <div className="carousel-content-wrap">
            {/* <h1>{sectionTitle}</h1> */}
          </div>
        </div>
      ) : (
        <div className="home-carousel">
          <div className="carousel-content-wrap">
            <p className="para-1">
              "I wanted a company that I could trust and depend on to handle a
              couple of important projects from start to finish. BPS delivered
              exceptionally well with great professionalism, integrity, and
              punctuality."
            </p>
            <p className="para-2">
              - Dick Parsons, Former Chairman of CBS, Citigroup and former
              Chairman and CEO of Time Warner.
            </p>
            {/* <div dangerouslySetInnerHTML={{ __html: quote.html }} /> */}
          </div>
        </div>
      )}
    </CallToAction>
  )
}

export const CallToAction = styled(BackgroundImage)`
  margin-top: 124px;

  height: ${props => (props.isSection ? "74vh" : "120vh")};
  @media only screen and (max-width: ${props =>
      props.theme.responsive.mobile}) {
    height: 60vh;
    margin-top: 0;
  }
  border-bottom: 4px solid white;
  width: 100%;
  background-size: cover;
  display: flex;
  color: white;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .home-carousel {
    position: relative;
    flex-direction: column;
    justify-content: end;
    background: linear-gradient(to right, #46232266, #23252600);
    padding-left: 2rem;
    width: 100%;
    @media only screen and (max-width: ${props =>
        props.theme.responsive.mobile}) {
      padding-left: 0;
      background: linear-gradient(to right, #4623228a, #2325268f);
    }

    .carousel-content-wrap {
      max-width: 29%;
      @media only screen and (max-width: ${props =>
          props.theme.responsive.mobile}) {
        max-width: 100%;
        padding-bottom: 1rem;
        flex-direction: column;
        justify-content: flex-end;
      }
      height: 100%;
      padding-top: 10%;
      display: flex;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      /* justify-content: center; */

      color: #462322;

      p {
        color: white;
        /* font-size: 1rem; */
        line-height: 1.6;
        @media only screen and (max-width: ${props =>
            props.theme.responsive.mobile}) {
          /* font-size: 1rem; */
          line-height: 1.6;
          text-align: center;
        }
        font-style: italic;
        margin-bottom: 10px;
      }
      .para-1 {
        text-shadow: 1px 2px 2px #232121;
        font-size: 1.4em;
      }
      .para-2 {
        font-size: 0.8em;
      }
    }

    height: 100%;
  }

  .section-carousel {
    position: relative;
    background: linear-gradient(to right, #46232266, #23252600);
    /* padding-left: 2rem; */
    width: 100%;
    @media only screen and (max-width: ${props =>
        props.theme.responsive.mobile}) {
      padding-left: 0;
      background: linear-gradient(to right, #4623228a, #2325268f);
    }

    .carousel-content-wrap {
      width: 100%;
      @media only screen and (max-width: ${props =>
          props.theme.responsive.mobile}) {
        max-width: 100%;
        /* padding-bottom: 1rem; */
      }
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;

      h1 {
        font-size: 4.5rem;
        @media only screen and (max-width: ${props =>
            props.theme.responsive.mobile}) {
          font-size: 3rem; /* padding-bottom: 1rem; */
        }
        text-align: center;
        text-transform: capitalize;
        width: 100%;
        text-shadow: 1px 1px 4px #000000d1;
        color: white;
        font-weight: 700;
        letter-spacing: 0.033em;
      }

      p {
        color: white;
        font-size: 1rem;
        line-height: 1.6;
        @media only screen and (max-width: ${props =>
            props.theme.responsive.mobile}) {
          font-size: 1rem;
          line-height: 1.6;
          text-align: center;
        }
        font-style: italic;
        margin-bottom: 10px;
      }
    }

    height: 100%;
  }
`
