import React from "react"
import Featured from "./featured"
import Slider from "./slider"
import CallToAction from "./action"
import Topic from "./topic"
import Grid from "./grid"
import InfoList from "./infoList"
import Roster from "./roster"
import Logos from "./logos"
import ProjectList from "./projectList"
import Form from "../../components/slices/form"
import Seo from "../seo"
import HomePageSlider from "../slices/homePageSlider"

export default function Controller({
  body,
  sectionTitle,
  metaTitle,
  metaDescription,
}) {
  return (
    <React.Fragment>
      <Seo title={metaTitle} description={metaDescription} />
      {body.map((slice, index) => {
        switch (slice.slice_label) {
          case "featured":
            return (
              <Featured
                key={index}
                backgroundImage={
                  slice.primary.featured_image.localFile.childImageSharp.fluid
                }
                quote={slice.primary.testimonial_quote}
              />
            )
          case "featured-section":
            return (
              <Featured
                key={index}
                isSection={true}
                backgroundImage={
                  slice.primary.featured_image.localFile.childImageSharp.fluid
                }
                sectionTitle={sectionTitle}
                quote={slice.primary.testimonial_quote}
              />
            )
          case "slider":
            return <Slider key={index} slider={slice} />
          case "grid-logo":
            return <Logos key={index} data={slice} />
          case "call-to-action":
            return (
              <CallToAction
                key={index}
                backgroundColor="#1e2a5b"
                action={slice}
              />
            )
          case "grid":
            return <Grid key={index} array={slice.items} />
          case "topic":
            return <Topic key={index} topic={slice} />
          case "info":
            return <InfoList key={index} data={slice} />
          case "roster":
            return <Roster key={index} data={slice} />
          case "project-list":
            return <ProjectList key={index} data={slice} />
          case "homepage-slider":
            return <HomePageSlider key={index} slider={slice.items} />
          case "contact-form":
            return <Form />
          default:
            return null
        }
      })}
    </React.Fragment>
  )
}
