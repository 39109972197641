import React from "react"

import styled from "styled-components"
import Burger from "../hamburger/index"
import Menu from "../menu/index"
import { Link } from "gatsby"

import { navigate } from "gatsby"

import ImageLogo from "../../../images/logo.png"

function Header({ open, setOpen, buttonColor = "white" }) {
  return (
    <HeaderContainer>
      <div>
        <Burger buttonColor={buttonColor} open={open} setOpen={setOpen} />
        <Menu open={open} setOpen={setOpen} />
      </div>
      <img
        onClick={() => navigate("/")}
        className="logo-image-src"
        src={ImageLogo}
      />
    </HeaderContainer>
  )
}

const HeaderContainer = styled.div`
  @media only screen and (min-width: ${props =>
      props.theme.responsive.mobile}) {
    display: none;
  }

  position: fixed;
  background: white;
  z-index: 700;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100px;
  /* border-bottom: 1px solid #b2b2b2; */

  img {
    width: 30%;
    padding-top: 10px;
    padding-bottom: 1rem;
    height: 100px;
    padding-right: 1rem;
  }
`

export default Header

const LinkWrapper = styled(Link)`
  height: 100%;
  cursor: pointer;
  color: none;
`
