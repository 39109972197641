import React from "react"
import styled from "styled-components"
import Slider from "react-slick"
import Img from "gatsby-image"
import { Link } from "gatsby"

export default function SimpleSlider({ slider }) {
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: "linear",
  }
  return (
    <Wrapper backgroundColor="#white">
      <Slider
        style={{ paddingLeft: "2rem", paddingRight: "2rem" }}
        {...settings}
      >
        {slider.items.map((item, index) => {
          return (
            <Slide key={index}>
              <Img
                height="4rem"
                fluid={item.photos.localFile.childImageSharp.fluid}
              />
            </Slide>
          )
        })}
      </Slider>
    </Wrapper>
  )
}

const ButtonContainer = styled.div`
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
  width: 100%;
  text-align: center;
`

const StyledLink = styled(Link)`
  -moz-appearance: none;
  border-radius: 0;
  text-transform: capitalize;
  border-width: 2px;
  cursor: pointer;
  font-family: Lato, Helvetica, Roboto, Arial, sans-serif;
  font-weight: 400;
  line-height: normal;
  margin: 0 0 20px;
  margin: 0 0 1.25rem;
  position: relative;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  padding: 26px 52px 27px;
  padding: 1.625rem 3.25rem 1.6875rem;
  font-size: 1pc;
  font-size: 1rem;
  background-color: transparent;
  border: ${props => `0.125rem solid ${props.border}`};
  color: ${props => props.color};
  transition: background-color 0.3s ease-out;
  &:hover {
    background-color: black;
    border: none;
    color: white;
  }
`

const Slide = styled.div``

const Title = styled.h1`
  color: black;
  font-size: 3rem;
  font-weight: 600;
  width: 100%;
  text-align: center;
  text-transform: capitalize;
  color: black;
  text-align: center;
  margin-top: 1.875rem;
  letter-spacing: 0.03em;
  font-size: 3rem;
  font-weight: 600;
  color: black;
  text-align: center;
  @media only screen and (max-width: ${props =>
      props.theme.responsive.mobile}) {
    font-size: 2.375rem;
  }
`

const Wrapper = styled.div`
  max-width: 1100px;
  margin-right: auto;
  margin-left: auto;
  /* align-items: center; */
  /* margin: 0 auto 1.875rem; */
  margin-top: 4rem;
  /* padding: 7.5rem 1.25rem; */
  /* margin-left:auto; */
  background-color: ${props => props.backgroundColor};
`
