import React from "react"
import styled from "styled-components"
import Slider from "react-slick"
import { Link } from "gatsby"
import GatsbyBackGroundImage from "gatsby-background-image"

export default function HomePageSlider({ slider }) {
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    fade: true,
    speed: 2000,
    autoplaySpeed: 3000,
  }
  console.log(`run`)
  return (
    <Wrapper>
      <Slider {...settings}>
        {slider.map((item, index) => {
          return (
            <Slide stylekey={index}>
              <StyledBackground
                fluid={item.photos.localFile.childImageSharp.fluid}
              >
                <div>
                  <p className="wrap">
                    “I wanted a company that I could trust and depend on to
                    handle a couple of important projects from start to finish.
                    BPS delivered exceptionally well with great professionalism,
                    integrity and punctuality.”
                  </p>

                  <span className="wrap parsons">
                    -Dick Parsons, Former Chairman of CBS, Citigroup and former
                    Chairman and CEO of Time Warner.
                  </span>
                </div>
              </StyledBackground>
            </Slide>
          )
        })}
      </Slider>
    </Wrapper>
  )
}

const StyledBackground = styled(GatsbyBackGroundImage)`
  width: 100%;
  height: 100%;
  background-repeat: repeat-y;
  background-position: bottom center;
  background-size: cover;
  > div {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    background-color: #00000085;

    span {
      margin-top: 1em;
      color: white;
      padding-left: 2em;
      padding-right: 2em;
    }
    p {
      /* font-weight: bold; */
      font-style: italic;
      width: 100%;
      padding-left: 2em;
      padding-right: 2em;
      color: white;
      font-size: 1.5em;
      text-align: center;
      .parsons {
        padding-top: 1em;
        color: white;
        font-size: 0.75em;
      }
    }
  }
`

const ButtonContainer = styled.div`
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
  width: 100%;
  font-weight: 600;
  text-align: center;
`

const Slide = styled.div`
  height: 95vh;
  min-height: 95vh;
  max-height: 95vh;
`

const Title = styled.h1`
  color: black;
  font-size: 3rem;
  font-weight: 600;
  width: 100%;
  text-align: center;
  text-transform: capitalize;
  color: black;
  text-align: center;
  margin-top: 1.875rem;
  letter-spacing: 0.03em;
  font-size: 3rem;
  font-weight: 600;
  color: black;
  text-align: center;
  @media only screen and (max-width: ${props =>
      props.theme.responsive.mobile}) {
    font-size: 2.375rem;
  }
`

const Wrapper = styled.div`
  margin-top: 120px;
  /* padding-top: 4em; */

  width: 100%;
  width: 100%;
`
