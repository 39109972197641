import React from "react"
import styled from "styled-components"
import Reaveal from "react-reveal/"
import Image from "gatsby-image"
import { doesLinkExist } from "./helpers"
import GatsbyBackgroundImage from "gatsby-background-image"

export default function ProjectList({ data }) {
  return (
    <Wrapper>
      <div className="container">
        {data.items.map((item, index) => {
          return (
            <article key={index} className="info-card-container">
              <div className="title">
                <Reaveal>
                  <div className="text-wrapper">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item.description.html,
                      }}
                    />
                    <span className="arrow-decoration"></span>
                  </div>
                </Reaveal>
              </div>
              <div className="image">
                <a
                  className={doesLinkExist(item) ? "" : "disabled"}
                  href={doesLinkExist(item)}
                  target="_blank"
                >
                  <BackgroundImage
                    fluid={item.image_thumbnail.localFile.childImageSharp.fluid}
                  >
                    <div className="project-carousel">
                      {doesLinkExist(item) ? <Icon /> : null}
                    </div>
                  </BackgroundImage>
                </a>
              </div>
            </article>
          )
        })}
      </div>
    </Wrapper>
  )
}
function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="white"
      height="4rem"
      width="4rem"
      x="0"
      y="0"
      enableBackground="new 0 0 314 314"
      version="1.1"
      viewBox="0 0 314 314"
      xmlSpace="preserve"
    >
      <path d="M37.191 314c-13.138 0-19.018-11.259-19.018-22.416V22.416C18.172 11.259 24.052 0 37.189 0c4.253 0 8.663 1.302 13.11 3.87l233.108 134.58c7.894 4.559 12.42 11.319 12.42 18.549 0 7.23-4.526 13.99-12.419 18.548L50.298 310.132C45.852 312.698 41.442 314 37.191 314zm-.002-300c-4.365 0-5.017 5.272-5.017 8.416v269.168c0 3.143.652 8.416 5.018 8.416 1.211 0 3.255-.346 6.108-1.993l233.109-134.584c3.394-1.959 5.42-4.361 5.42-6.424s-2.026-4.465-5.421-6.425L43.298 15.994C40.445 14.346 38.401 14 37.189 14z"></path>
    </svg>
  )
}

export const Wrapper = styled.div`
  .container {
    width: 100%;
    em {
      color: white;
    }
    /* margin-top: 10px; */
    article {
      display: flex;
      min-height: 500px;
      height: 500px;
      max-height: 500px;
      background-color: #1e2a5b;
      @media only screen and (max-width: ${props =>
          props.theme.responsive.mobile}) {
        display: block;
        max-height: none;

        min-height: 300px;
        height: 100%;
      }

      &:nth-child(odd) {
        flex-direction: row-reverse;
      }

      > div {
        width: 100%;
        /* max-width: 50%;
        min-width: 50%; */
      }

      .title {
        height: 100%;
        width: 100%;
        h1 {
          color: white;
        }
        display: flex;
        .text-wrapper {
          width: 83.333333%;

          li {
            margin-bottom: 10px;
          }
          max-width: 900px;
          margin: auto;
          padding: 24px 40px;
          @media only screen and (max-width: ${props =>
              props.theme.responsive.mobile}) {
            width: 100%;
          }

          p {
            font-family: inherit;
            font-size: 1pc;
            font-size: 1rem;
            font-weight: 300;
            margin-bottom: 20px;
            color: white;
            line-height: 1.6;
            margin-bottom: 1.25rem;
            text-rendering: optimizeLegibility;
          }

          h1 {
            letter-spacing: 0.03em;
            font-size: 2rem;
            line-height: 1.6;

            font-weight: 600;
            margin-bottom: 1.5rem;
            /* color: black; */
            display: block;
            /* color: black; */
            margin-top: 15px;
            text-transform: capitalize;
          }
        }
      }
    }
  }
`

const StyledButton = styled.div`
  -moz-appearance: none;
  border-radius: 0;
  border-width: 2px;
  cursor: pointer;
  font-weight: 400;
  line-height: normal;
  margin: 0 0 20px;
  margin: 0 0 1.25rem;
  position: relative;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  padding: 26px 52px 27px;
  padding: 1.625rem 3.25rem 1.6875rem;
  font-size: 1pc;
  font-size: 1rem;
  background-color: transparent;
  border: 0.125rem solid black;
  color: white;
  transition: background-color 0.3s ease-out;
  &:hover {
    background-color: ${props => props.color};
    color: ${props => props.backgroundColor};
  }
`

export const BackgroundImage = styled(GatsbyBackgroundImage)`
  height: 100%;
  width: 100%;
  min-height: 300px;
  .project-carousel {
    height: 100%;
    min-height: 300px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &:hover {
      background-color: #00000061;
      transition: duration 1.3 background-color;
      svg {
        color: red;
      }
    }
  }
`
