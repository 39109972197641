import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import AniLink from "gatsby-plugin-transition-link/AniLink"

export default function ActionCard({
  action,
  backgroundColor,
  color = "white",
  border = "white",
}) {
  return (
    <Card backgroundColor={backgroundColor}>
      <ContentWrapper>
        <CardParagraph color={color}>
          {action.primary.call_to_action_message.text}
        </CardParagraph>
        <ButtonContainer>
          {action.primary.call_to_action_copy ? (
            <AniLink
              direction="left"
              duration={2.5}
              bg="rgb(154, 36, 116)"
              cover
              to={action.primary.nav_link}
            >
              <StyledLink color={color} border={border}>
                {action.primary.call_to_action_copy}
              </StyledLink>
            </AniLink>
          ) : null}
        </ButtonContainer>
      </ContentWrapper>
    </Card>
  )
}

export const Card = styled.div`
  min-height: 500px;
  padding-top: 5px;
  background: ${props => props.backgroundColor};
`

export const ContentWrapper = styled.div`
  max-width: 750px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 auto 30px;
  padding: 0 auto 1.875rem;

  @media only screen and (max-width: ${props =>
      props.theme.responsive.mobile}) {
    margin: 0;
    margin: 0;
  }

  padding: 7.5rem 1.25rem;
`

export const CardParagraph = styled.div`
  font-size: 2rem;
  text-align: center;
  line-height: 1.6;
  color: ${props => props.color};
  @media only screen and (max-width: ${props =>
      props.theme.responsive.mobile}) {
    font-size: ${props => props.theme.responsive.pMobile};
  }
`
export const ButtonContainer = styled.div`
  margin-top: 2rem;
  width: 100%;
  display: flex;
  justify-content: center;
`

const StyledLink = styled.div`
  -moz-appearance: none;
  border-radius: 0;
  border-width: 2px;
  cursor: pointer;
  font-family: Lato, Helvetica, Roboto, Arial, sans-serif;
  font-weight: 400;
  line-height: normal;
  margin: 0 0 20px;
  margin: 0 0 1.25rem;
  position: relative;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  padding: 26px 52px 27px;
  padding: 1.625rem 3.25rem 1.6875rem;
  font-size: 1pc;
  font-size: 1rem;
  background-color: transparent;
  border: ${props => `0.125rem solid ${props.border}`};
  color: ${props => props.color};
  transition: background-color 0.3s ease-out;
  &:hover {
    background-color: white;
    color: black;
  }
`
