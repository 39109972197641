import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import AniLink from "gatsby-plugin-transition-link/AniLink"

export default function Topic({
  color,
  border = "black",
  backgroundColor,
  title,
  service,
  hoverColor = "white",
  to,
  topic,
}) {
  const { topic_name, nav_redirect_link, nav_redirect_title } = topic.primary

  return (
    <Section color={color} backgroundColor={backgroundColor}>
      <h1>{topic_name}</h1>
      <div className="underline"></div>
      <ButtonContainer>
        <AniLink
          // className="info-button"
          cover
          to={nav_redirect_link}
          direction="left"
          duration={2.5}
          bg="rgb(154, 36, 116)"
        >
          <StyledButton
            backgroundColor={backgroundColor}
            border={border}
            color={color}
            // to={nav_redirect_link}
          >
            {nav_redirect_title}
          </StyledButton>
        </AniLink>
      </ButtonContainer>
    </Section>
  )
}

const Section = styled.div`
  background-color: ${props => props.backgroundColor};
  padding: 7.5rem 1.25rem 3.25rem;
  background-color:#9a2474;

  @media only screen and (max-width: ${props =>
    props.theme.responsive.mobile}) {
        padding: 6rem 1.25rem;

      /* font-size: ${props => props.theme.responsive.h1Mobile}; */
    }
  }


  .underline {
    margin-left: auto;
    width: 15%;
    opacity: 0.6;
    margin-top: 2rem;
    margin-right: auto;

    opacity: 0.7;
    margin-bottom: 2rem;
    border-bottom: 0.02em solid white;
  }
  h1 {
    font-size: 3rem;
    margin-top:4rem;
    font-weight: 600;
    color: white;
    letter-spacing: 0.03em;
    text-align: center;

    @media only screen and (max-width: ${props =>
      props.theme.responsive.mobile}) {
      font-size: ${props => props.theme.responsive.h1Mobile};
    }
  }
`

const ButtonContainer = styled.div`
  margin-top: 2rem;
  width: 100%;
  display: flex;
  justify-content: center;
`

const StyledButton = styled.div`
  -moz-appearance: none;
  border-radius: 0;
  border-width: 2px;
  cursor: pointer;
  font-weight: 400;
  line-height: normal;
  margin: 0 0 20px;
  margin: 0 0 1.25rem;
  position: relative;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  padding: 26px 52px 27px;
  padding: 1.625rem 3.25rem 1.6875rem;
  font-size: 1pc;
  font-size: 1rem;
  background-color: transparent;
  border: ${props => `0.125rem solid white`};
  color: white;
  transition: background-color 0.3s ease-out;
  margin-bottom: 2.5rem;
  &:hover {
    background-color: white;
    color: black;
  }
`
