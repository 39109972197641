import { createGlobalStyle } from "styled-components"
import reset from "styled-reset-advanced"

export const theme = {
  responsive: {
    mobile: "600px",
    tablet: "768px",
    laptop: "992px",
    monitor: "1100px",
    h1Mobile: "2.375rem",
    pMobile: "1.5rem;",
  },

  backgroundColor: "white",
  fontColor: "black",
  padding: "2rem",
}

export const GlobalStyle = createGlobalStyle`
  ${reset};
  * {
    /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
  }
  body {
    background-color:${theme.backgroundColor};
    font-family: 'poppins', sans-serif;
    font-size:1rem;
    color:${theme.fontColor};
    /* height: 100vh; */
    /* -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale; */
    justify-content: center;
    text-rendering: optimizeLegibility;
  }

`
