import React from "react"
import styled from "styled-components"
import Reveal from "react-reveal"
import Image from "gatsby-image"

export default function Roster({ data }) {
  return (
    <Wrapper>
      <div className="wrap">
        {data.items.map((item, index) => {
          return (
            <article key={index + "233"}>
              <div className="description">
                <Reveal bottom>
                  <h1>{item.name_of_person}</h1>
                  <Image
                    className="roster-mobile-image"
                    style={{ marginBottom: "1rem" }}
                    fluid={
                      item.portrait_of_person.localFile.childImageSharp.fluid
                    }
                  />
                  <div
                    dangerouslySetInnerHTML={{ __html: item.biography.html }}
                  />
                </Reveal>
              </div>
              <div className="profile-center">
                <div className="profile-image">
                  <Reveal right>
                    <Image
                      fluid={
                        item.portrait_of_person.localFile.childImageSharp.fluid
                      }
                    />
                  </Reveal>
                </div>
              </div>
            </article>
          )
        })}
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin-top: 140px;
  width: 100%;
  background-color: #1e2a5b;
  /* min-height: 100vh; */

  article {
    width: 100%;
    background-color: #1e2a5b;
    min-height: 70vh;
    max-height: 100%;
    border-bottom: 1px solid #9a2474;
    display: grid;
    padding: 4.5rem 0;

    grid-template-columns: 1fr 1fr;
    @media only screen and (max-width: ${props =>
        props.theme.responsive.mobile}) {
      padding: 2em;
      display: flex;
    }
    .profile-center {
      /* height: 100%;
      display: flex;
      justify-content: center;
      flex-direction: column; */
    }
    .profile-image {
      @media only screen and (max-width: ${props =>
          props.theme.responsive.mobile}) {
        display: none;
      }

      max-width: 400px;
      margin-left: auto;
      margin-right: auto;
      width: 100%;
      height: 400px;
      max-height: 400px;
    }
    .description {
      .roster-mobile-image {
        @media only screen and (min-width: ${props =>
            props.theme.responsive.mobile}) {
          display: none;
        }
      }

      h1 {
        line-height: 0;
        padding-bottom: 1em;
      }

      @media only screen and (max-width: ${props =>
          props.theme.responsive.mobile}) {
        h1 {
          font-size: 1.375rem;
          text-align: center;
        }
      }

      /* max-width: 900px;
      width: 83.3333333333%;
      max-width: 900px;
      margin: auto;
      padding: 0px 40px; */
      p {
        font-family: inherit;
        font-size: 1pc;
        font-size: 1rem;
        color: white;
        font-weight: 300;
        margin-bottom: 20px;
        line-height: 1.6;
        margin-bottom: 1.25rem;
        text-rendering: optimizeLegibility;
      }

      strong {
        font-style: italic;
      }
      h1 {
        letter-spacing: 0.03em;
        font-size: 3rem;

        @media only screen and (max-width: ${props =>
            props.theme.responsive.mobile}) {
          font-size: 2rem;
        }

        font-weight: 600;
        margin-bottom: 1.5rem;
        color: black;
        display: block;
        color: #9a2474;
        margin-top: 15px;
        text-transform: capitalize;
      }
      width: 100%;
    }
  }
`
