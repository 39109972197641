import React from "react"
import BackgroundImage from "gatsby-background-image"
import styled from "styled-components"
import { Link } from "gatsby"

export default function Grid({ array }) {
  return (
    <div style={{ backgroundColor: "#9a2474" }}>
      <Wrapper>
        <ServicesGrid>
          {array.map((item, index) => {
            return (
              <StyledLink key={index} to="/services">
                <GridPoint
                  key={index}
                  title={item.name_of_photo}
                  image={item.grid_photo.localFile.childImageSharp.fluid}
                />
              </StyledLink>
            )
          })}
        </ServicesGrid>
      </Wrapper>
    </div>
  )
}

const StyledLink = styled(Link)`
  text-decoration: none;
`
const Wrapper = styled.div`
  /* padding-top: 5px; */
  width: 100%;
  max-width: 1100px;
  margin-right: auto;
  margin-left: auto;
  background-color: #9a2474;
`

const ServicesGrid = styled.div`
  display: grid;
  width: 100%;
  padding-bottom: 6em;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 3em;
  padding-top: 2em;

  @media only screen and (max-width: ${props =>
      props.theme.responsive.mobile}) {
    margin-left: 0;
    grid-template-columns: repeat(1, auto);
    margin-right: 0;
  }
`

const GridColumn = styled(BackgroundImage)`
  cursor: pointer;
  height: 300px;
  min-height: 300px;
  display: flex;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  width: 100%;
  background-position: center center;
  object-fit: cover;
  background-size: cover;
  /* padding: 1em; */
  .grid-carousel {
    height: 100%;
    padding: 1em;
    height: 100%;
    width: 100%;
    text-align: center;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #00000054;
    width: 100%;
    h1 {
      text-align: center;
      font-weight: 300;
      font-size: 2em;
    }
    &:hover {
      background-color: rgba(154, 36, 116, 0.45);
      /* background-color: #00000054; */
    }
  }
`

export function GridPoint({ image, title }) {
  return (
    <GridColumn fluid={image}>
      <div className="grid-carousel">
        <h1>{title}</h1>
      </div>
    </GridColumn>
  )
}
